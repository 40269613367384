import React from 'react';
import './Popover.css';

interface PopoverProps {
  onAddComponent: () => void;
  
  image: string;
}

export const Popover: React.FC<PopoverProps> = ({onAddComponent, image}) => {

  return (
    <div className="popover-wrapper-custom">      
      <img src={image} alt='elements' onClick={() => onAddComponent()} />
    </div>
  );
};

