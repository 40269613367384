import React from 'react';
import './ElementsPopover.css';

interface ElementsPopoverItemProps {
  icon: React.ReactNode;
  title: string;
  description: string;
  color?: string;
  onClick: () => void;
  className: string;
}

const ElementsPopoverItem: React.FC<ElementsPopoverItemProps> = ({ icon, title, description, onClick, color, className }) => {
  return (
    <div className={`elements-popover-item ${className}`} onClick={onClick}>
      <div className="item-info" >
      <p className="item-description" style={color ? {backgroundColor: color} : {}}>{description}</p>
        <p className="item-title">{title}</p>
      </div>
    </div>
  );
};

export default ElementsPopoverItem;
