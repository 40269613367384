import React from 'react';
import { Table } from './elements/Table';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGripLines } from '@fortawesome/free-solid-svg-icons';

interface DraggableTableProps {
  component: any;
  stageScale: number;
  stagePosition: { x: number; y: number };
  isEmpty: boolean;
  onMouseDown: (id: number, e: React.MouseEvent<HTMLDivElement>) => void; // Use React's native type for divs
}

const DraggableTable: React.FC<DraggableTableProps> = ({ component, stageScale, isEmpty, stagePosition, onMouseDown }) => (
  <div
    key={component.id}
    style={{
      position: 'absolute',
      top: component.y * stageScale + stagePosition.y,
      left: component.x * stageScale + stagePosition.x,
      width: '800px',
     
      // padding: '4px',
      overflow: 'auto',
      backgroundColor: 'white',
      transform: `scale(${stageScale})`,
      transformOrigin: 'top left',
      borderRadius: '14px',
    }}
  >
    <Table isEmpty={isEmpty} />
    <div
      style={{
        position: 'absolute',
        top: 0,
        right: 0,
        cursor: 'move',
        backgroundColor: '#f0f0f0',
        padding: '4px',
        borderBottomLeftRadius: '4px',
        zIndex: 100,
      }}
      onMouseDown={(e) => onMouseDown(component.id, e)}
    >
      <FontAwesomeIcon icon={faGripLines} style={{ fontSize: '16px' }} />
    </div>
  </div>
);

export default DraggableTable;
