import React, { useRef, useEffect } from 'react';
import { Stage, Layer, Rect } from 'react-konva';
import useStageHandlers from '../hooks/useStageHandlers';
import useDottedPattern from '../hooks/useDottedPattern';
import CanvasComponentRenderer from './CanvasComponentRenderer';
import DraggableTable from './DraggableTable';
import { CanvasComponent } from '../hooks/useCanvas';
import './Canvas.css';
import { KonvaEventObject } from 'konva/lib/Node';

interface CanvasProps {
  components: CanvasComponent[];
  setComponents: React.Dispatch<React.SetStateAction<CanvasComponent[]>>;
  config?: {
    components: CanvasComponent[];
    stageScale: number;
    stagePosition: { x: number; y: number };
  };
}

const Canvas: React.FC<CanvasProps> = ({ components, setComponents, config }) => {
  const {
    stageRef,
    stageScale,
    stagePosition,
    handleWheel,
    handleStageDragMove,
    setStageDraggable,
    setStageScale,
    setStagePosition,
  } = useStageHandlers(setComponents);

  const dottedPattern = useDottedPattern();
  const draggingComponentId = useRef<number | null>(null);
  const startDragPos = useRef<{ x: number; y: number } | null>(null);

  // Load config if provided
  useEffect(() => {
    if (config) {
      setComponents(config.components);
      setStageScale(config.stageScale);
      setStagePosition(config.stagePosition);
    }
  }, [config, setComponents, setStageScale, setStagePosition]);

  const handleMouseDownKonva = (id: number, e: KonvaEventObject<MouseEvent>) => {
    e.evt.stopPropagation();
    draggingComponentId.current = id;
    startDragPos.current = { x: e.evt.clientX, y: e.evt.clientY };
    setStageDraggable(false);
    window.addEventListener('mousemove', handleMouseMove);
    window.addEventListener('mouseup', handleMouseUp);
  };

  const handleMouseDownReact = (id: number, e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
    draggingComponentId.current = id;
    startDragPos.current = { x: e.clientX, y: e.clientY };
    setStageDraggable(false);
    window.addEventListener('mousemove', handleMouseMove);
    window.addEventListener('mouseup', handleMouseUp);
  };

  const handleMouseMove = (e: MouseEvent) => {
    if (draggingComponentId.current !== null && startDragPos.current) {
      const deltaX = (e.clientX - startDragPos.current.x) / stageScale;
      const deltaY = (e.clientY - startDragPos.current.y) / stageScale;

      setComponents((prevComponents) =>
        prevComponents.map((component) =>
          component.id === draggingComponentId.current
            ? { ...component, x: component.x + deltaX, y: component.y + deltaY }
            : component
        )
      );

      startDragPos.current = { x: e.clientX, y: e.clientY };
    }
  };

  const handleMouseUp = () => {
    draggingComponentId.current = null;
    startDragPos.current = null;
    setStageDraggable(true);
    window.removeEventListener('mousemove', handleMouseMove);
    window.removeEventListener('mouseup', handleMouseUp);
    console.log(components);
  };

  return (
    <div className="canvas-container" style={{ position: 'relative' }}>
      <Stage
        ref={stageRef}
        width={window.innerWidth}
        height={window.innerHeight}
        scaleX={stageScale}
        scaleY={stageScale}
        x={stagePosition.x}
        y={stagePosition.y}
        draggable
        onWheel={handleWheel}
        onDragMove={handleStageDragMove}
        style={{ background: '#fafafa' }}
      >
        <Layer>
          {dottedPattern && (
            <Rect
              x={-5000}
              y={-5000}
              width={10000}
              height={10000}
              fillPatternImage={dottedPattern}
              fillPatternScale={{ x: 1 / stageScale, y: 1 / stageScale }}
              fillPatternRepeat="repeat"
            />
          )}
        </Layer>
        <Layer>
          {components.map((component) => (
            <CanvasComponentRenderer
              key={component.id}
              component={component}
              onMouseDown={handleMouseDownKonva}
            />
          ))}
        </Layer>
      </Stage>
      {components.map(
        (component) =>
          (component.type === 'table' || component.type === 'table_blank') && (
            <DraggableTable
              key={component.id}
              component={component}
              stageScale={stageScale}
              stagePosition={stagePosition}
              onMouseDown={handleMouseDownReact}
              isEmpty={component.type === 'table_blank'}
            />
          )
      )}
    </div>
  );
};

export default Canvas;
