import React from 'react';
import ElementsPopoverSearchBar from './ElementsPopoverSearchBar';
import ElementsPopoverSection from './ElementsPopoverSection';
import './ElementsPopover.css';

interface ElementsPopoverProps {
  onAddComponent: (type: string) => void;
}

const ElementsPopover: React.FC<ElementsPopoverProps> = ({onAddComponent}) => {
  const tableItems = [
    {
      icon: "📄",
      title: "Blank",
      description: "Add a blank table to start capturing information",
      onClick: () => { onAddComponent("table_blank") },
    },
    {
      icon: "💰",
      title: "Spending",
      description: "Capture and categorize spending & budgets",
      onClick: () => { onAddComponent("table") },
      color: "#B1ECEE"
    },
    {
      icon: "💰",
      title: "Spending",
      description: "Capture and categorize spending & budgets",
      color: "#ACEED2",
    },
    {
      icon: "💰",
      title: "Spending",
      description: "Capture and categorize spending & budgets",
      color: "#B5B2F5",
    },
    {
      icon: "💰",
      title: "Spending",
      description: "Capture and categorize spending & budgets",
      color: "#FFD0D0",
    },
  ];


  return (
    <div className="elements-popover-wrapper">
      <ElementsPopoverSearchBar />
      <div>
      <ElementsPopoverSection title="Tables" items={tableItems} />
      </div>
      <div>
        <img src='/assets/temp/elements.png' alt='elements' width={610} />
      </div>
      
    </div>
  );
};

export default ElementsPopover;
