import React, { useState } from 'react';
import Modal from 'react-modal';
import ShareModal from './ShareModal'; // Import the ShareModal component
import './Header.css';

const Header: React.FC = () => {
  const [shareModalIsOpen, setShareModalIsOpen] = useState(false);

  const openShareModal = () => setShareModalIsOpen(true);
  const closeShareModal = () => setShareModalIsOpen(false);

  return (
    <div className="header">
      <img src="assets/logo.png" alt="Logo" className="logo" />
      <div className="workspace-name">Workspace Name</div>
      <div className="header-actions">
        <div className="avatars">
          <img src="assets/avatar1.png" alt="User 1" className="avatar" />
          <img src="assets/avatar2.png" alt="User 2" className="avatar" />
        </div>
        <button className="sharing-button" onClick={openShareModal}>Sharing</button>
        <div className="leave-button">
          <button>Leave</button>
        </div>
      </div>

      {/* Share Modal */}
      <Modal
        isOpen={shareModalIsOpen}
        onRequestClose={closeShareModal}
        style={{
          content: {
            width: '700px',
            height: '340px',
            borderRadius: '16px',
            border: 'none',
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            padding: '0',
            boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
          },
        }}
        shouldCloseOnOverlayClick={true}
      >
        <ShareModal onClose={closeShareModal} />
      </Modal>
    </div>
  );
};

export default Header;
