import React, { useEffect, useState } from 'react';
import './Table.css';

interface TableRow {
  address: string;
  visited: string;
  decision: string;
  price: string;
  comment: string;
  floorplan: boolean;
}

export const Table: React.FC<{ isEmpty: boolean }> = ({ isEmpty }) => {
  const [rows, setRows] = useState<TableRow[]>([]);

  const handleCellChange = (rowIndex: number, columnKey: keyof Omit<TableRow, 'floorplan'>, newValue: string) => {
    const updatedRows = [...rows];
    updatedRows[rowIndex][columnKey] = newValue;
    setRows(updatedRows);
  };

  const addRow = () => {
    const emptyRow: TableRow = {
      address: '',
      visited: '',
      decision: '',
      price: '',
      comment: '',
      floorplan: false,
    };
    setRows((prevRows) => [...prevRows, emptyRow]);
  };

  useEffect(() => {


    const predefinedRows: TableRow[] = [
      { address: 'N12 3FV', visited: 'Yes', decision: 'No', price: '£390,950', comment: 'Building is totally rundown', floorplan: true },
      { address: 'E11 4F3', visited: 'Yes', decision: 'Make offer', price: '£450,000', comment: 'Needs updating, but great layout', floorplan: true },
      { address: 'E11 H34', visited: 'No', decision: '', price: '£429,890', comment: '', floorplan: false },
      { address: 'N8 2XA', visited: 'Visit booked for 13:12', decision: '', price: '£379,999', comment: 'Ask about the construction across the street', floorplan: true },
    ];

    if (isEmpty) {
      setRows([]);
    } else {
      setRows([...predefinedRows]);
    }
  }, [isEmpty]);

  return (
    <div className="table-component">
      <table>
        <thead>
          <tr>
            <th>Address</th>
            <th>Visited</th>
            <th>Decision</th>
            <th>Price</th>
            <th>Comment</th>
            <th>Floorplan</th>
          </tr>
        </thead>
        <tbody>
          {rows.map((row, rowIndex) => (
            <tr key={rowIndex}>
              {Object.keys(row).map((columnKey) => (
                <td key={columnKey}>
                  {columnKey !== 'floorplan' ? (
                    typeof row[columnKey as keyof TableRow] === 'string' ? (
                      <input
                        type="text"
                        value={row[columnKey as keyof TableRow] as string}
                        onChange={(e) => handleCellChange(rowIndex, columnKey as keyof Omit<TableRow, 'floorplan'>, e.target.value)}
                      />
                    ) : (
                      <span>-</span>
                    )
                  ) : (
                    row.floorplan ? <button>PDF</button> : <span>-</span>
                  )}
                </td>
              ))}
            </tr>
          ))}
          <tr className="add-row">
            <td colSpan={6} onClick={addRow}>
              + New
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};
