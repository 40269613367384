import { useRef, useState } from 'react';

const useStageHandlers = (setComponents: React.Dispatch<React.SetStateAction<any[]>>) => {
  const stageRef = useRef<any>(null);
  const [stageScale, setStageScale] = useState(1);
  const [stagePosition, setStagePosition] = useState({ x: 0, y: 0 });
  const [stageDraggable, setStageDraggable] = useState(true);

  const handleWheel = (e: any) => {
    e.evt.preventDefault();
    const stage = stageRef.current;
    if (!stage) return;

    const oldScale = stage.scaleX();
    const pointer = stage.getPointerPosition();
    if (!pointer) return;

    const scaleBy = 1.02;
    const newScale = e.evt.deltaY < 0 ? oldScale * scaleBy : oldScale / scaleBy;
    const clampedScale = Math.min(Math.max(newScale, 0.1), 5);

    const mousePointTo = {
      x: (pointer.x - stage.x()) / oldScale,
      y: (pointer.y - stage.y()) / oldScale,
    };

    stage.scale({ x: clampedScale, y: clampedScale });
    setStageScale(clampedScale);

    const newPos = {
      x: pointer.x - mousePointTo.x * clampedScale,
      y: pointer.y - mousePointTo.y * clampedScale,
    };

    stage.position(newPos);
    setStagePosition(newPos);
    stage.batchDraw();
  };

  const handleStageDragMove = (e: any) => {
    if (!stageDraggable) return;
    const newPos = { x: e.target.x(), y: e.target.y() };
    setStagePosition(newPos);
  };

  return {
    stageRef,
    stageScale,
    stagePosition,
    stageDraggable,
    setStageDraggable,
    handleWheel,
    handleStageDragMove,
    setStageScale,
    setStagePosition,
  };
};

export default useStageHandlers;
