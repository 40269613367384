import React, { useState, useRef, useEffect } from 'react';
import { usePopper } from 'react-popper';
import './Toolbar.css';
import ElementsPopover from './popovers/ElementsPopover';
import Modal from 'react-modal';
import { Popover } from './popovers/Popover';

Modal.setAppElement('#root'); // Set your app root for accessibility

interface ToolbarProps {
  onAddComponent: (type: string, src?: string, width?: number, height?: number, x?: number, y?: number) => void;
  onLoadConfig: () => void;
}

interface PopoverButtonProps {
  type: string;
  icon: string;
  label: string;
  onAddComponent?: (type: string) => void;
  isVisible: boolean;
  onTogglePopover: () => void;
}

const PopoverButton: React.FC<PopoverButtonProps> = ({
  type,
  icon,
  onAddComponent,
  isVisible,
  onTogglePopover,
}) => {
  const popoverRef = useRef<HTMLDivElement | null>(null);
  const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(null);
  const [referenceElement, setReferenceElement] = useState<HTMLButtonElement | null>(null);

  const { styles, attributes, update } = usePopper(referenceElement, popperElement, {
    placement: 'right',
    modifiers: [{ name: 'offset', options: { offset: [8, 0] } }],
  });

  useEffect(() => {
    if (isVisible && update) {
      update();
    }
  }, [isVisible, update]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        popoverRef.current &&
        !popoverRef.current.contains(event.target as Node) &&
        referenceElement &&
        !referenceElement.contains(event.target as Node)
      ) {
        onTogglePopover(); // Close the popover when clicking outside
      }
    };

    if (isVisible) {
      document.addEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isVisible, referenceElement, onTogglePopover]);

  return (
    <div className="popover-wrapper" ref={popoverRef}>
      <button
        className={type !== 'ai' ? "tool-button" : 'ai-button'}
        ref={setReferenceElement}
        onClick={type === 'comments' ? () => onAddComponent?.(type) : onTogglePopover}
      >
        <img src={icon} alt={type} className={isVisible ? "selected tool-icon" : "tool-icon"} />
      </button>
      {isVisible && type === 'ai' && (
        <div
          className="ai-panel"
          style={{
            position: 'fixed',
            top: '61px',
            right: 0,
            width: '380px',
            backgroundColor: 'white',
            zIndex: 1000,
            borderRadius: '14px',
          }}
        >
          <img src="/images/ai.png" alt="ai" style={{ width: '100%' }} />
        </div>
      )}
      {isVisible && type !== 'ai' && type === 'table' ? (
        <div
          ref={(node) => {
            setPopperElement(node);
            popoverRef.current = node;
          }}
          className="popover-content"
          style={styles.popper}
          {...attributes.popper}
        >
          <ElementsPopover onAddComponent={onAddComponent!} />
        </div>
      ) : (
        isVisible && type !== 'ai' && (
          <div
            ref={(node) => {
              setPopperElement(node);
              popoverRef.current = node;
            }}
            className="popover-content"
            style={styles.popper}
            {...attributes.popper}
          >
            <Popover onAddComponent={() => onAddComponent?.(type)} image={`/assets/temp/${type}.png`} />
          </div>
        )
      )}
    </div>
  );
};

const Toolbar: React.FC<ToolbarProps> = ({ onAddComponent, onLoadConfig }) => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [popoverVisible, setPopoverVisible] = useState<string | null>(null);

  const togglePopover = (type: string) => {
    setPopoverVisible((prev) => (prev === type ? null : type));
  };

  const buttons = [
    { type: 'text', icon: '/assets/text.svg', label: 'Texts', onAddComponent: () => onAddComponent('text') },
    { type: 'draw', icon: '/assets/draw.svg', label: 'Draws', onAddComponent: () => onAddComponent('draw') },
    { type: 'table', icon: '/assets/elements.svg', label: 'Elements list', onAddComponent },
    { type: 'shape', icon: '/assets/shapes.svg', label: 'Shapes list', onAddComponent: () => onAddComponent('circle') },
    { type: 'sticky', icon: '/assets/sticky.svg', label: 'Sticky list', onAddComponent: () => onAddComponent('image', '/images/sticky_1.png') },
    { type: 'comments', icon: '/assets/comment.svg', label: 'Comments', onAddComponent: () => onAddComponent('image', '/images/comment.png') },
    { type: 'embed', icon: '/assets/embed.svg', label: 'Embed', onAddComponent: () => {
      onAddComponent('image', '/images/embed1.png', undefined, undefined, 50, 50);
      onAddComponent('image', '/images/embed2.png', undefined, undefined, 220, 50);
    }},
    { type: 'connected', icon: '/assets/connected.svg', label: 'Connected', onAddComponent: () => onAddComponent('image', '/images/connected_bank.png') },
    { type: 'ai', icon: '/assets/ai.svg', label: 'AI' },
  ];

  const customButtons = [
    { type: 'rectangle', icon: '/assets/templates.svg', label: 'Templates list' },
  ];

  return (
    <div className="toolbar">
      <PopoverButton
        key={customButtons[0].type}
        type={customButtons[0].type}
        icon={customButtons[0].icon}
        label={customButtons[0].label}
        onAddComponent={() => setModalIsOpen(true)}
        isVisible={false}
        onTogglePopover={() => setModalIsOpen(true)}
      />
      {buttons.map((button) => (
        <PopoverButton
          key={button.type}
          type={button.type}
          icon={button.icon}
          label={button.label}
          onAddComponent={button?.onAddComponent}
          isVisible={popoverVisible === button.type && button.type !== 'comments'}
          onTogglePopover={() => togglePopover(button.type)}
        />
      ))}
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={() => setModalIsOpen(false)}
        style={{
          content: {
            maxWidth: '1250px',
            height: '100vh',
            borderRadius: '16px',
            border: 'none',
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            overflow: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
          },
        }}
        shouldCloseOnOverlayClick={true}
      >
        <img
          src="/assets/temp/templates.png"
          alt="elements"
          style={{ width: '100%' }}
          onClick={() => {
            onLoadConfig(); // Load the configuration on image click
            setModalIsOpen(false);
          }}
        />
      </Modal>
    </div>
  );
};

export default Toolbar;
