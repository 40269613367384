import React, { useState } from 'react';
import './ShareModal.css';

interface ShareModalProps {
  onClose: () => void;
}

const ShareModal: React.FC<ShareModalProps> = ({ onClose }) => {
  const [email, setEmail] = useState('');
  const [permission, setPermission] = useState('can view all');

  const members = [
    { avatar: 'assets/avatar1.png', name: 'Matt B.', role: 'owner' },
    { avatar: 'assets/avatar2.png', name: 'Laura M.', role: 'can edit all' },
    { avatar: 'assets/avatar1.png', name: 'Mom', role: 'can view selected parts' },
  ];

  const handleInvite = () => {
    // Add invite logic here
    onClose(); // Close the modal after clicking "invite"
};

  return (
    <div className="share-modal">
      <div className="share-modal-header">
        <img src="assets/logo.png" alt="Logo" className="modal-logo" />
        <h2>Share board [NAME] with:</h2>
      </div>
      <div className="share-modal-content">
        <div className="invite-section">
          <input
            type="text"
            placeholder="Enter email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            className="invite-input"
          />
          <select
            value={permission}
            onChange={(e) => setPermission(e.target.value)}
            className="permission-dropdown"
          >
            <option value="can view all">can view all</option>
            <option value="can edit all">can edit all</option>
            <option value="can view selected parts">can view selected parts</option>
          </select>
          <button onClick={handleInvite} className="invite-button">invite</button>
        </div>
        <h3>Already on this board</h3>
        <div className="members-section">
          {members.map((member, index) => (
            <div key={index} className="member">
              <img src={member.avatar} alt={member.name} className="member-avatar" />
              <span className="member-name">{member.name}</span>
              <select
                value={member.role}
                onChange={(e) => console.log(`${member.name}'s role changed to ${e.target.value}`)}
                className="role-dropdown"
              >
                <option value="owner">owner</option>
                <option value="can edit all">can edit all</option>
                <option value="can view selected parts">can view selected parts</option>
              </select>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ShareModal;
