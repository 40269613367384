import { useEffect, useState } from 'react';

const useDottedPattern = () => {
  const [dottedPattern, setDottedPattern] = useState<HTMLImageElement | null>(null);

  useEffect(() => {
    const patternCanvas = document.createElement('canvas');
    patternCanvas.width = 20;
    patternCanvas.height = 20;
    const context = patternCanvas.getContext('2d');
    if (context) {
      context.fillStyle = '#fafafa';
      context.fillRect(0, 0, patternCanvas.width, patternCanvas.height);
      context.fillStyle = '#ccc';
      context.beginPath();
      context.arc(10, 10, 1, 0, Math.PI * 2);
      context.fill();
    }
    const img = new Image();
    img.src = patternCanvas.toDataURL();
    img.onload = () => {
      setDottedPattern(img);
    };
  }, []);

  return dottedPattern;
};

export default useDottedPattern;
