import React from 'react';
import './App.css';
import Toolbar from './components/Toolbar';
import Canvas from './components/Canvas';
import Header from './components/Header';
import useCanvas from './hooks/useCanvas';

const App: React.FC = () => {
  const { components, addComponent, setComponents } = useCanvas();

  const handleLoadConfig = () => {
    const exampleConfig = [
      {
          "id": 1,
          "type": "image",
          "x": 1384.2383246262743,
          "y": 1904.7073599143619,
          "src": "/images/area_like.png"
      },
      {
          "id": 2,
          "type": "image",
          "x": 300,
          "y": 100,
          "src": "/images/borrow.png"
      },
      {
          "id": 3,
          "type": "image",
          "x": 1218.323857339936,
          "y": 1799.1642128593076,
          "src": "/images/budget_area.png"
      },
      {
          "id": 4,
          "type": "image",
          "x": 700,
          "y": 100,
          "src": "/images/check_financial_resilience.png"
      },
      {
          "id": 5,
          "type": "image",
          "x": 2560.5576135875335,
          "y": -108.8135784879118,
          "src": "/images/comparing_results.png"
      },
      {
          "id": 6,
          "type": "image",
          "x": -382,
          "y": 1494,
          "src": "/images/connected_bank.png"
      },
      {
          "id": 7,
          "type": "image",
          "x": 2725.0098981538013,
          "y": 1851.1955582694438,
          "src": "/images/course_mortgage.png"
      },
      {
          "id": 8,
          "type": "image",
          "x": 1295.6508884214973,
          "y": -86.50038069822347,
          "src": "/images/figuring_out.png"
      },
      {
          "id": 9,
          "type": "image",
          "x": 874.5828678260999,
          "y": -53.23908602272128,
          "src": "/images/how_much_can_you_borrow.png"
      },
      {
          "id": 10,
          "type": "image",
          "x": 837.5026648875618,
          "y": 650.3491115785037,
          "src": "/images/how_much_will_it_cost.png"
      },
      {
          "id": 11,
          "type": "image",
          "x": 1993.5355330905904,
          "y": 1979.240608815613,
          "src": "/images/interest_rate.png"
      },
      {
          "id": 12,
          "type": "image",
          "x": -411.43474868425903,
          "y": 857.7015822306835,
          "src": "/images/list_of_flats.png"
      },
      {
          "id": 13,
          "type": "image",
          "x": 1271.337690631809,
          "y": 715.0893396958938,
          "src": "/images/monthly_cost.png"
      },
      {
          "id": 14,
          "type": "image",
          "x": 1319.225380886688,
          "y": 621.0878169030012,
          "src": "/images/monthly_repayments.png"
      },
      {
          "id": 15,
          "type": "image",
          "x": 500,
          "y": 700,
          "src": "/images/monthly_repayment.png"
      },
      {
          "id": 16,
          "type": "image",
          "x": 869.9579950396155,
          "y": -139.66611635042312,
          "src": "/images/mortgage_calculations.png"
      },
      {
          "id": 17,
          "type": "image",
          "x": 760.7715735275269,
          "y": 1328.9021567873579,
          "src": "/images/mortgage_options.png"
      },
      {
          "id": 18,
          "type": "image",
          "x": 2583.856344844742,
          "y": 1980.549999623071,
          "src": "/images/mortgage_overpayments.png"
      },
      {
          "id": 19,
          "type": "image",
          "x": 832.4851527692974,
          "y": -1.0473347355092493,
          "src": "/images/much_borrow.png"
      },
      {
          "id": 20,
          "type": "image",
          "x": 1768.9618020218463,
          "y": 714.5362944870371,
          "src": "/images/overall_cost.png"
      },
      {
          "id": 21,
          "type": "image",
          "x": 324,
          "y": 1478,
          "src": "/images/pie_chart.png"
      },
      {
          "id": 22,
          "type": "image",
          "x": 2532.6309639580545,
          "y": -46.6714461255474,
          "src": "/images/renting_result.png"
      },
      {
          "id": 23,
          "type": "image",
          "x": 1739.4850246134577,
          "y": 1249.5248735403432,
          "src": "/images/sticky_1.png"
      },
      {
          "id": 24,
          "type": "image",
          "x": 234,
          "y": 1464,
          "src": "/images/sticky_2.png"
      },
      {
          "id": 25,
          "type": "image",
          "x": -111.46022112203114,
          "y": 829.1061856284973,
          "src": "/images/sticky_3.png"
      },
      {
          "id": 26,
          "type": "image",
          "x": 756.3536799571796,
          "y": 1895.7318527564817,
          "src": "/images/where_buy.png"
      },
      {
          "id": 27,
          "type": "image",
          "x": 808,
          "y": 1820,
          "src": "/images/where_can_you_buy.png"
      },
     
      {
          "id": 29,
          "type": "image",
          "x": -404.2226011055005,
          "y": 417.3287867339951,
          "src": "/images/embed1.png"
      },
      {
          "id": 30,
          "type": "image",
          "x": -225.11130055275015,
          "y": 419.8719755614677,
          "src": "/images/embed2.png"
      },
      {
          "id": 31,
          "type": "image",
          "x": -362,
          "y": -162,
          "src": "/images/how_does_template_work.png"
      },
      {
          "id": 32,
          "type": "image",
          "x": 760,
          "y": 720,
          "src": "/images/montly_repayment.png"
      },
      {
          "id": 33,
          "type": "image",
          "x": 72,
          "y": -124,
          "src": "/images/renting_long_term.png"
      },
      {
          "id": 34,
          "type": "image",
          "x": 88,
          "y": -168,
          "src": "/images/renting_projections.png"
      },
      {
          "id": 35,
          "type": "image",
          "x": -381.8521675879171,
          "y": 342.1925632834391,
          "src": "/images/saved_information_links.png"
      },
      {
          "id": 36,
          "type": "image",
          "x": -387.31758205437455,
          "y": -135.65879102718722,
          "src": "/images/template_overview.png",
          "width": 410,
          "height": 410
      },
      {
          "id": 37,
          "type": "image",
          "x": 2010.6824179456255,
          "y": 1874.3412089728126,
          "src": "/images/check_your_financial.png",
          height: 30,
          width: 360
      },
      {
          "id": 38,
          "type": "image",
          "x": 1348.6824179456255,
          "y": 1516.3412089728126,
          "src": "/images/sticky_4.png"
      },
      {
          "id": 39,
          "type": "image",
          "x": 2532.6824179456253,
          "y": 238.34120897281278,
          "src": "/images/chart.png"
      },
      {
          "id": 40,
          "type": "image",
          "x": -413.3175820543745,
          "y": 906.3412089728128,
          "src": "/images/table.png"
      },
      {
        "id": 28,
        "type": "image",
        "x": -15.852167587916972,
        "y": 422.95835321641226,
        "src": "/images/comment.png"
    },
  ];
    setComponents(exampleConfig);
  };
  

  return (
    <div className="app-container">
      <Header />
      <div className="main-content">
        <Toolbar onAddComponent={addComponent} onLoadConfig={handleLoadConfig} />
        <Canvas components={components} setComponents={setComponents} />
      </div>
    </div>
  );
};

export default App;
