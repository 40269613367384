import React from 'react';
import './ElementsPopover.css';

interface ElementsPopoverSearchBarProps {
  placeholder?: string;
}

const ElementsPopoverSearchBar: React.FC<ElementsPopoverSearchBarProps> = ({ placeholder = "Search elements" }) => {
  return (
    <div className="popover-header">
      <input type="text" placeholder={placeholder} className="elements-popover-search" />
    </div>
  );
};

export default ElementsPopoverSearchBar;
